import { sortByDatetime } from '$/app/utils';
import { IResidentReport } from '$shared/services/resident-reports/resident-report.schema';
import { createEntityAdapter } from '@ngrx/entity';

export const residentReportsAdapter = createEntityAdapter<IResidentReport>({
  sortComparer: sortByDatetime('createdAt', true)
});

export const initialState = residentReportsAdapter.getInitialState({
  loading: false,
  loaded: false,
  error: null
});
