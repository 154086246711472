import { DateTime } from 'luxon';
import { regex } from '../regex';
import { toBase64URL } from '../utils/base64-utils';

export const OPAQUE_TOKEN_REGEX = regex`^([A-Fa-f0-9]{8})\\.([A-Za-z\\d+/_-]{1,100})$`;

export type ParsedOpaqueToken = {
  expiration: DateTime;
  nonce: string;
  cleanToken: string;
};

export function parseOpaqueToken(
  token: string | null | undefined
): ParsedOpaqueToken | undefined {
  if (!token) {
    return undefined;
  }

  const cleanToken = toBase64URL(token);

  const [, expirationHex, nonce] = cleanToken.match(OPAQUE_TOKEN_REGEX) ?? [];

  if (!expirationHex || !nonce) {
    return undefined;
  }

  return {
    expiration: DateTime.fromSeconds(parseInt(expirationHex, 16)),
    nonce,
    cleanToken
  };
}
