import { map, zipObject } from 'lodash';

export function boolDictionaryToArray<
  T extends Record<string, boolean> = Record<string, boolean>
>(dict: T): Array<keyof T> {
  return Object.keys(dict).filter((key) => dict[key]) as Array<keyof T>;
}

export function arrayToBooleanDictionary<T extends string>(
  values: T[]
): Record<T, boolean> {
  return zipObject(
    values,
    map(values, () => true)
  ) as Record<T, boolean>;
}

export function isBooleanDictionary<T extends Record<string, any>>(
  value: any
): value is T {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  for (const key in value) {
    if (typeof value[key] !== 'boolean') {
      return false;
    }
  }

  return true;
}
