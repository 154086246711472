import { ForgotPasswordPage } from '$/app/pages/authentication/forgot-password/forgot-password.page';
import { LoginPage } from '$/app/pages/authentication/login/login.page';
import { ResetPasswordPage } from '$/app/pages/authentication/reset-password/reset-password.page';
import { SignupPage } from '$/app/pages/authentication/signup/signup.page';
import { FACILITY_WORKSPACE_DEFAULT_ROUTE } from '$/config';
import { createAlcomyRoute } from '$/lib/create-alcomy-route';
import { hideSplashScreen } from '$/lib/splash-screen';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AlcGeofenceViolationPage } from './pages/errors/geofence-violation/geofence-violation.page';
import { AlcomyRoutes } from './utils/routing/alcomy-route';

export const APP_ROUTES: AlcomyRoutes = [
  { path: '', pathMatch: 'full', redirectTo: FACILITY_WORKSPACE_DEFAULT_ROUTE },
  {
    path: '',
    canActivate: [
      () => {
        hideSplashScreen();
      }
    ],
    children: [
      {
        path: 'login',
        component: LoginPage,
        title: 'Login'
      },
      {
        path: 'signup',
        component: SignupPage,
        title: 'Signup',
        data: {
          noAuth: true
        },
        resolve: {
          facilityId: (route: ActivatedRouteSnapshot) =>
            route.queryParams.facilityId,
          token: (route: ActivatedRouteSnapshot) => route.queryParams.token
        }
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordPage,
        data: {
          noAuth: true
        },
        title: 'Forgot Password'
      },
      {
        path: 'reset-password',
        component: ResetPasswordPage,
        title: 'Reset Password',
        data: {
          noAuth: true
        },
        resolve: {
          token: (route: ActivatedRouteSnapshot) => route.queryParams.token
        }
      },
      createAlcomyRoute({
        path: 'view-report',
        componentName: 'ViewReportPage',
        importer: () => import('./pages/view-report/view-report.page'),
        title: 'View Report'
      }),
      createAlcomyRoute({
        path: 'document-form/:useFor',
        componentName: 'AlcDocumentsPortalFormShellPage',
        importer: () =>
          import(
            './pages/documents/documents-portal/documents-portal-form-shell/documents-portal-form-shell.page'
          ),
        // TODO(2024-02-01): This needs a dynamic title, or a different solution entirely?
        title: 'LIC 601 Form'
      }),
      {
        path: 'documents-portal',
        // canActivateChild: [hasFeatureFlags('documents-forms')],
        loadChildren: () => import('./pages/documents/documents-portal.routes')
      },
      createAlcomyRoute({
        path: 'access-denied',
        componentName: 'AlcAccessDeniedPage',
        importer: () =>
          import('./pages/errors/access-denied/access-denied.page')
      }),
      {
        path: 'geofence-violation',
        component: AlcGeofenceViolationPage
      },
      // And now, for something completely different...
      {
        path: 'experiments',
        loadChildren: () => import('./pages/experiments/experiments.routes')
      }
    ]
  },
  {
    path: '',
    loadChildren: () =>
      import('./pages/facility-workspace/facility-workspace.routes')
  }
];
