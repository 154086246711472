@if (filteredOptions().length) {
  <div>
    <ion-button
      [matMenuTriggerFor]="menu"
      fill="clear"
      shape="round"
      size="default"
      color="none"
      class="menu-button size-12"
      [ngClass]="iconCss()"
      alcClickStopPropagation
    >
      <ion-icon
        slot="icon-only"
        src="/assets/icon/{{ iconSet() }}/{{ icon() }}.svg"
        [color]="color()"
      />
    </ion-button>

    <mat-menu #menu="matMenu">
      @for (option of filteredOptions(); track option.id) {
        <button
          mat-menu-item
          [disabled]="option.disabled"
          (click)="optionSelected.emit(option.id)"
        >
          <div class="flex items-center">
            <ion-icon
              [color]="option.iconColor"
              class="mr-4 shrink-0 text-2xl"
              src="assets/icon/{{ option.iconSet }}/{{ option.icon }}.svg"
            />

            <div>
              <p class="m-0">{{ option.label }}</p>
              @if (option.description) {
                <p class="m-0 leading-none text-medium-dark">
                  <small>{{ option.description }}</small>
                </p>
              }
            </div>
          </div>
        </button>
      }
    </mat-menu>
  </div>
}
