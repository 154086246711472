import { pdfGenerationDemoActions } from '$/app/pages/experiments/pdf-generation-demo/pdf-generation-demo.actions';
import { GivePrnFormActions } from '$/app/pages/medications/dashboard/give-prn-form/give-prn-form.actions';
import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { MedicationDetailInfoPageActions } from '$/app/pages/medications/residents/medication-detail/medication-detail-info/medication-detail-info.actions';
import { MedicationListPageActions } from '$/app/pages/medications/residents/medication-list/medication-list.actions';
import { PrnFormActions } from '$/app/pages/medications/shared/components/prn-form/prn-form.actions';
import { DashboardResidentLogsPageActions } from '$/app/pages/notebook/dashboard/dashboard-resident-logs/dashboard-resident-logs.actions';
import { MissedBowelMovementsPageActions } from '$/app/pages/notebook/dashboard/missed-bowel-movements/missed-bowel-movements.actions';
import { ActivityLogInfoModalActions } from '$/app/pages/notebook/shared/logs/activity-logs/activity-log-info-modal/activity-log-info.modal.actions';
import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import { ResidentPickerModalActions } from '$/app/pages/residents/resident-picker-modal/resident-picker.actions';
import { ResidentsListPageActions } from '$/app/pages/residents/residents-list/residents-list.actions';
import { ViewReportPageActions } from '$/app/pages/view-report/view-report.actions';
import { ResidentsApiService } from '$/app/services/api/residents.service';
import { EffectHelpersService } from '$/app/services/utils/effect-helpers.service';
import { ResidentSelectComponentActions } from '$/app/shared/form-controls/resident-select/resident-select.actions';
import { ActivityLogModalActions } from '$/app/shared/pages/forms/log-forms/activity-log-modal/activity-log.modal.actions';
import { OmitMedicationFormPageActions } from '$/app/shared/pages/forms/omit-medication-form/omit-medication.modal.actions';
import { ResidentsSelectors } from '$/app/store/residents/residents.selectors';
import { getAllPages, reducePaginatedResponses } from '$/app/utils';
import { ApiData } from '$/app/utils/api-data';
import { ResidentGraph } from '$/models';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import {
  catchError,
  mergeMap,
  switchMap,
  withLatestFrom
} from 'rxjs/operators';
import { ofRoute } from '../lib/ofRoute';
import { ResidentsApiActions } from './actions/residents-api.actions';

@Injectable()
export class ResidentsEffects {
  private readonly store = inject(Store);
  private readonly actions$ = inject(Actions);
  private readonly residentsService = inject(ResidentsApiService);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadResidents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentsListPageActions.loadResidents,
        ResidentSelectComponentActions.loadResidents,
        OmitMedicationFormPageActions.loadResidents,
        MedicationDestructionItemFormActions.loadResidents,
        ResidentPickerModalActions.loadResidents
      ),
      this.effectHelpers.apiRequest({
        description: 'Load residents',
        onRequest: (action) =>
          this.residentsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.residentsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (residents) => {
          const responseData = new ApiData(
            'residents',
            residents,
            ResidentsApiActions.loadResidentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => ResidentsApiActions.loadResidentsFail({ error })
      })
    );
  });

  getResidents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        DashboardResidentLogsPageActions.getResidents,
        GivePrnFormActions.getResidentsAndPrns,
        PrnFormActions.getResidentsAndPrns,
        ResidentFacesheetPageActions.getResidents,
        ActivityLogModalActions.getResidents,
        ActivityLogInfoModalActions.getResidents,
        MissedBowelMovementsPageActions.getResidents
      ),
      this.effectHelpers.apiRequest({
        description: 'Get residents',
        onRequest: (action) => this.residentsService.getAll(action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residents',
            response,
            ResidentsApiActions.getResidentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => ResidentsApiActions.getResidentsFail({ error })
      })
    );
  });

  fetchResident$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentFacesheetPageActions.fetchResident,
        pdfGenerationDemoActions.fetchResident,
        MedicationDetailInfoPageActions.fetchResident,
        MedicationListPageActions.fetchResident,
        ViewReportPageActions.fetchResident
      ),
      this.effectHelpers.apiRequest({
        description: 'Fetch resident',
        onRequest: (action) =>
          this.residentsService.get(action.id, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residents',
            response,
            ResidentsApiActions.fetchResidentSuccess,
            {
              dispatchOnEmpty: true,
              singleRecord: true,
              payloadKey: 'resident'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          ResidentsApiActions.fetchResidentFail({
            error
          })
      })
    );
  });

  createResident$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentModalActions.createResident),
      this.effectHelpers.apiRequest({
        description: 'Create resident',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.residentsService.create(action.resident, action.params);
        },
        onSuccess: (resident) =>
          ResidentsApiActions.createResidentSuccess({
            resident
          }),
        onError: (error) => ResidentsApiActions.createResidentFail({ error })
      })
    );
  });

  updateResident$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        ResidentFacesheetPageActions.updateAvatar,
        ResidentModalActions.updateResident,
        ResidentFacesheetPageActions.moveResident
      ),
      this.effectHelpers.apiRequest({
        description: 'Update resident',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.residentsService.patch(
            action.id,
            action.changes,
            action.params
          );
        },
        onSuccess: (resident) =>
          ResidentsApiActions.updateResidentSuccess({
            resident
          }),
        onError: (error) => ResidentsApiActions.updateResidentFail({ error })
      })
    );
  });

  // Pre-fetch resident for the follow routes if resident doesn't exist
  routesWithResidentEffect$ = createEffect(() => {
    return this.actions$.pipe(
      ofRoute([/:residentId/g]),
      withLatestFrom(this.store.select(ResidentsSelectors.selectResident)),
      switchMap(([action, resident]) => {
        const residentId: string = action.payload.routerState.params.residentId;
        if (!resident) {
          return this.residentsService.get(residentId).pipe(
            mergeMap((resident: ResidentGraph) => {
              const responseData = new ApiData('residents', resident);
              responseData.setPrimaryAction(
                ResidentsApiActions.fetchResidentSuccess,
                { payloadKey: 'resident' }
              );
              return responseData.getActions();
            }),
            catchError((error) => {
              return of(ResidentsApiActions.fetchResidentFail({ error }));
            })
          );
        } else {
          return EMPTY;
        }
      })
    );
  });
}
