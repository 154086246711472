import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ResidentReportsEffects } from './resident-reports.effects';
import { residentReportsFeature } from './resident-reports.reducer';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature(residentReportsFeature),
    EffectsModule.forFeature([ResidentReportsEffects])
  ],
  providers: [ResidentReportsEffects]
})
export class ResidentReportsStoreModule {}
