import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import { getDateRangePresetDictionary } from '$shared/services/filters/date-range-filter/date-range-presets';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { DateTime } from 'luxon';

const currentYearPresets = [
  'today',
  'yesterday',
  'past7Days',
  'past30Days',
  'past60Days',
  'past90Days'
];

/**
 * Returns a date range picker object and fills in a default value for
 * any fields that are not defined. This is usually used before passing
 * the date range picker object to the date range picker component or
 * before any network requests that utilize the information from the date
 * range picker object.
 */
export function getDateRange(
  dateRange: IDateRangePickerValue | null | undefined,
  ft: FacilityTime,
  defaultPreset = 'past30Days'
): IDateRangePickerValue {
  const type = dateRange?.type ?? 'preset';
  const preset = dateRange?.preset ?? defaultPreset;
  const currentYear = ft.createDate().year;
  let year = dateRange?.year ?? currentYear;

  // Certain presets are only available for the current year (i.e. they are
  // relative to now, and now is in the current year). Therefore, the year must
  // be set to the current year.
  if (type === 'preset' && currentYearPresets.includes(preset)) {
    year = currentYear;
  }

  const presets = getDateRangePresetDictionary(year, ft);

  let startDate: DateTime;
  let endDate: DateTime;

  if (type === 'preset') {
    const _preset = presets[preset];

    startDate = _preset.range.start;
    endDate = _preset.range.end;
  } else {
    const _preset = presets[defaultPreset];

    startDate = dateRange?.startDate
      ? ft.createDateTime(dateRange.startDate)
      : presets[defaultPreset].range.start;

    endDate = dateRange?.endDate
      ? ft.createDateTime(dateRange.endDate)
      : presets[defaultPreset].range.end;
  }

  const range: IDateRangePickerValue = {
    type,
    preset,
    year,
    startDate,
    endDate
  };

  return range;
}
