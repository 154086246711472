import { Create, Delete, Load, Update } from '$/models';
import { IResidentReport } from '$shared/services/resident-reports/resident-report.schema';
import { createActionGroup, props } from '@ngrx/store';

export const ResidentReportsPageActions = createActionGroup({
  source: 'Resident Reports Page',
  events: {
    'Load Resident Reports': props<Load>(),
    'Create Resident Report': props<Create<IResidentReport>>(),
    'Update Resident Report': props<Update<IResidentReport>>(),
    'Delete Resident Report': props<Delete>()
  }
});
