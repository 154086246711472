export const sleepChecks = [
  {
    id: 'asleep',
    name: 'Asleep'
  },
  {
    id: 'awake',
    name: 'Awake'
  }
] as const;

export const sleepCheckIds = sleepChecks.map(({ id }) => id);

export type SleepCheckId = (typeof sleepCheckIds)[number];
