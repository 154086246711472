import { pdfGenerationDemoActions } from '$/app/pages/experiments/pdf-generation-demo/pdf-generation-demo.actions';
import { MedicationDestructionItemFormActions } from '$/app/pages/medications/dashboard/medication-destructions/medication-destruction-item-form/medication-destruction-item.actions';
import { DashboardResidentLogsPageActions } from '$/app/pages/notebook/dashboard/dashboard-resident-logs/dashboard-resident-logs.actions';
import { ResidentFacesheetPageActions } from '$/app/pages/residents/resident-detail/facesheet/facesheet.actions';
import { ResidentModalActions } from '$/app/pages/residents/resident-modal/resident.modal.actions';
import { ResidentPickerModalActions } from '$/app/pages/residents/resident-picker-modal/resident-picker.actions';
import { ResidentsListPageActions } from '$/app/pages/residents/residents-list/residents-list.actions';
import { ResidentSelectComponentActions } from '$/app/shared/form-controls/resident-select/resident-select.actions';
import {
  State,
  initialState,
  residentsAdapter
} from '$/app/store/residents/residents.state';
import { getPaginationData, getRecords } from '$/app/utils';
import { Action, createReducer, on } from '@ngrx/store';
import {
  ResidentsApiActions,
  ResidentsWebsocketActions
} from './actions/residents-api.actions';
import { ResidentsGeneralActions } from './actions/residents-gen.actions';

const reducer = createReducer(
  initialState,

  on(
    ResidentSelectComponentActions.loadResidents,
    ResidentPickerModalActions.loadResidents,
    MedicationDestructionItemFormActions.loadResidents,
    DashboardResidentLogsPageActions.getResidents,
    ResidentFacesheetPageActions.fetchResident,
    pdfGenerationDemoActions.fetchResident,
    ResidentFacesheetPageActions.updateAvatar,
    ResidentFacesheetPageActions.moveResident,
    ResidentModalActions.updateResident,
    ResidentsListPageActions.loadResidents,
    ResidentModalActions.createResident,
    (state) => {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
  ),

  on(ResidentsApiActions.loadResidentsSuccess, (state, action) => {
    const pagination = getPaginationData(state, action.residents);
    const data = getRecords(action.residents);

    return residentsAdapter.setAll(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ResidentsApiActions.getResidentsSuccess, (state, action) => {
    const pagination = getPaginationData(state, action.residents);
    const data = getRecords(action.residents);

    return residentsAdapter.upsertMany(data, {
      ...state,
      pagination,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    ResidentsApiActions.fetchResidentSuccess,
    ResidentsApiActions.createResidentSuccess,
    (state, action) => {
      return residentsAdapter.upsertOne(action.resident, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }
  ),

  on(ResidentsApiActions.updateResidentSuccess, (state, action) => {
    return residentsAdapter.updateOne(action.resident, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ResidentsApiActions.deleteResidentSuccess, (state, action) => {
    return residentsAdapter.removeOne(action.id, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(
    ResidentsApiActions.loadResidentsFail,
    ResidentsApiActions.getResidentsFail,
    ResidentsApiActions.fetchResidentFail,
    ResidentsApiActions.createResidentFail,
    ResidentsApiActions.updateResidentFail,
    (state, action) => {
      return {
        ...state,
        loading: false,
        error: action.error
      };
    }
  ),

  on(
    ResidentsWebsocketActions.residentsCreated,
    ResidentsWebsocketActions.residentsPatched,
    (state, action) => {
      return residentsAdapter.upsertOne(action.resident, {
        ...state
      });
    }
  ),

  on(ResidentsWebsocketActions.residentsRemoved, (state, action) => {
    return residentsAdapter.removeOne(action.id, {
      ...state
    });
  }),

  on(ResidentsGeneralActions.addResidents, (state, action) => {
    return residentsAdapter.upsertMany(action.residents, {
      ...state,
      loading: false,
      loaded: true,
      error: null
    });
  }),

  on(ResidentsGeneralActions.clearResidents, () => {
    return residentsAdapter.removeAll({
      ...initialState
    });
  })
);

export function residentsReducer(state = initialState, action: Action): State {
  return reducer(state, action);
}
