<div class="flex shrink-0 items-center justify-center">
  @if (gender() || !name()) {
    <div
      class="rounded-full"
      [ngStyle]="{ 'height.px': size(), 'width.px': size() }"
    >
      <img
        class="rounded-full"
        [ngStyle]="{ 'height.px': size(), 'width.px': size() }"
        [src]="src()"
        (error)="url.set(null)"
      />
    </div>
  } @else {
    <div
      class="flex items-center justify-center rounded-full @container"
      [ngStyle]="{
        'height.px': size(),
        'width.px': size(),
        'background-color': hslColor()
      }"
    >
      <span class="inline-block text-[50cqw] font-bold text-white">
        {{ initials() }}
      </span>
    </div>
  }
</div>
