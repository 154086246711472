import { getPaginatedData } from '$/app/utils';
import { createFeature, createReducer, on } from '@ngrx/store';
import {
  ResidentReportsApiActions,
  ResidentReportsGeneralActions,
  ResidentReportsWsActions
} from './resident-reports.actions';
import { initialState, residentReportsAdapter } from './resident-reports.state';

export const residentReportsFeature = createFeature({
  name: 'residentReports',
  reducer: createReducer(
    initialState,
    on(
      ResidentReportsApiActions.loadResidentReportsSuccess,
      (state, action) => {
        return residentReportsAdapter.setAll(
          getPaginatedData(action.residentReports),
          {
            ...state,
            loading: false,
            loaded: true
          }
        );
      }
    ),

    on(ResidentReportsApiActions.createResidentReportSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(ResidentReportsApiActions.updateResidentReportSuccess, (state) => {
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null
      };
    }),

    on(
      ResidentReportsApiActions.deleteResidentReportSuccess,
      (state, action) => {
        return residentReportsAdapter.removeOne(action.id, {
          ...state,
          loading: false,
          loaded: true,
          error: null
        });
      }
    ),

    on(
      ResidentReportsApiActions.loadResidentReportsFail,
      ResidentReportsApiActions.createResidentReportFail,
      ResidentReportsApiActions.updateResidentReportFail,
      ResidentReportsApiActions.deleteResidentReportFail,
      (state, action) => {
        return {
          ...state,
          loading: false,
          error: action.error
        };
      }
    ),

    on(
      ResidentReportsWsActions.residentReportsCreated,
      ResidentReportsWsActions.residentReportsPatched,
      (state, action) => {
        return residentReportsAdapter.upsertOne(action.residentReport, state);
      }
    ),

    on(ResidentReportsWsActions.residentReportsRemoved, (state, action) => {
      return residentReportsAdapter.removeOne(action.id, state);
    }),

    on(ResidentReportsGeneralActions.addResidentReports, (state, action) => {
      return residentReportsAdapter.upsertMany(action.residentReports, {
        ...state,
        loading: false,
        loaded: true,
        error: null
      });
    }),

    on(ResidentReportsGeneralActions.clearResidentReports, (state) => {
      return residentReportsAdapter.removeAll({
        ...state,
        loading: false,
        loaded: false,
        error: null
      });
    })
  )
});
