import { keyBy } from 'lodash';
import { StateId } from '../lookups/states.lookup';
import { IFormConfig } from './types';

const formConfigs: readonly IFormConfig[] = [
  {
    digitalFormId: 'US-CA/LIC 601 - Identification and Emergency Information',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'id-and-emergency',
    signers: [
      // TODO: Find a way to represent either/or
      // {
      //   role: 'resident',
      //   pdfSignatureField: null
      // },
      {
        role: 'residentRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 602A - Physicians Report',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'physicians-report',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      },
      {
        role: 'physician',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 603 - Preplacement Appraisal Information',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'preplacement-appraisal-information',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      },
      {
        role: 'facilityRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId:
      'US-CA/LIC 605A - Release of Client Resident Medical Information',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'release-of-medical-information',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId:
      'US-CA/LIC 613 - Personal Rights - Adult Community Care Facilities',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'personal-rights',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId:
      'US-CA/LIC 621 - Client Resident Personal Property and Valuables',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'personal-properties-and-valuables',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 624 - Unusual Incident Injury Report',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'incident-report',
    signers: []
  },
  {
    digitalFormId: 'US-CA/LIC 624A - Death Report',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'death-report',
    signers: []
  },
  {
    digitalFormId: 'US-CA/LIC 627C - Consent for Emergency Medical Treatment',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'medical-consent',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: 'client-representative signature 1',
        signatureDateField: 'date 1'
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 9158 - Telecommunications Device Notification',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'telecommunications-device-notification',
    signers: [
      // TODO(2024-03-01): Find a way to represent either/or
      // {
      //   role: 'resident',
      //   pdfSignatureField: null
      // },
      {
        role: 'residentRep',
        pdfSignatureField: null
      },
      {
        role: 'facilityRep',
        pdfSignatureField: null
      }
    ]
  },
  {
    digitalFormId: 'US-CA/LIC 9172 - Functional Capability Assessment',
    state: 'US-CA',
    schemaVersion: 1,
    appPath: null,
    useFor: 'functional-capability-assessment',
    signers: [
      {
        role: 'residentRep',
        pdfSignatureField: null
      },
      {
        role: 'facilityRep',
        pdfSignatureField: null
      }
    ]
  }
] as const;

const formConfigsByDigitalFormId = keyBy(formConfigs, 'digitalFormId');
const formConfigsByUse = keyBy(
  formConfigs,
  (item) => `${item.state}/${item.useFor}`
);

export type FormConfigUses = (typeof formConfigs)[number]['useFor'];
export type DigitalFormId = (typeof formConfigs)[number]['digitalFormId'];

export function getFormConfig(
  digitalFormId?: string | null
): IFormConfig | undefined {
  if (!digitalFormId) {
    return;
  }

  return formConfigsByDigitalFormId[digitalFormId];
}

export function getFormConfigByUse(
  stateId: StateId,
  useFor: FormConfigUses
): IFormConfig | undefined {
  return formConfigsByUse[`${stateId}/${useFor}`];
}

export function documentHasForm<T extends { digitalFormId?: string }>(
  document: T
): boolean {
  return document?.digitalFormId
    ? !!getFormConfig(document.digitalFormId)
    : false;
}
