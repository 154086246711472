import { CountryId, countryIds } from '../lookups/countries.lookup';
import {
  FacilityTypeId,
  facilityTypeIds
} from '../lookups/facility-types.lookup';
import { StateId, stateIds } from '../lookups/states.lookup';
import { TypeBox } from '../type-box';

const table = TypeBox.LooseObject({
  id: TypeBox.Id(),
  orgId: TypeBox.Id(),

  name: TypeBox.String(),
  displayName: TypeBox.OptionalNullable(TypeBox.String()),
  type: TypeBox.Enum(facilityTypeIds),
  facilityNumber: TypeBox.OptionalNullable(TypeBox.String()),
  capacity: TypeBox.Integer(),
  isActive: TypeBox.Boolean(),
  isDiscounted: TypeBox.Optional(TypeBox.Boolean()),
  regionalOffice: TypeBox.OptionalNullable(TypeBox.String()),
  logoFileId: TypeBox.OptionalNullable(TypeBox.Id()),

  address1: TypeBox.OptionalNullable(TypeBox.String()),
  address2: TypeBox.OptionalNullable(TypeBox.String()),
  city: TypeBox.OptionalNullable(TypeBox.String()),
  state: TypeBox.Enum(stateIds),
  postalCode: TypeBox.OptionalNullable(TypeBox.String()),
  country: TypeBox.Enum(countryIds),

  createdAt: TypeBox.DateTimeString(),
  createdBy: TypeBox.OptionalNullable(TypeBox.Id()),
  updatedAt: TypeBox.OptionalNullable(TypeBox.DateTimeString()),
  updatedBy: TypeBox.OptionalNullable(TypeBox.Id())
});

export const facilitySchemas = {
  table,
  api: {
    create: TypeBox.setRequired(table, ['name', 'capacity']),
    patch: TypeBox.Partial(table)
  }
};

export type IFacility = TypeBox.Infer<typeof table>;

export class Facility implements IFacility {
  id: string;
  orgId: string;
  name: string;
  displayName?: string | null;
  type: FacilityTypeId;
  facilityNumber?: string | null;
  capacity: number;
  isActive: boolean;
  isDiscounted?: boolean;
  regionalOffice?: string | null;
  logoFileId?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state: StateId;
  postalCode?: string | null;
  country: CountryId;

  createdAt: string;
  createdBy?: string | null;
  updatedAt?: string | null;
  updatedBy?: string | null;

  constructor(data: IFacility = {} as IFacility) {
    this.id = data.id;
    this.orgId = data.orgId;
    this.name = data.name;
    this.displayName = data.displayName;
    this.type = data.type;
    this.facilityNumber = data.facilityNumber;
    this.capacity = data.capacity;
    this.isActive = data.isActive;
    this.isDiscounted = data.isDiscounted;
    this.regionalOffice = data.regionalOffice;
    this.logoFileId = data.logoFileId;
    this.address1 = data.address1;
    this.address2 = data.address2;
    this.city = data.city;
    this.state = data.state;
    this.postalCode = data.postalCode;
    this.country = data.country;

    this.createdAt = data.createdAt;
    this.createdBy = data.createdBy;
    this.updatedAt = data.updatedAt;
    this.updatedBy = data.updatedBy;
  }
}
