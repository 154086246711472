import { ResidentReportsPageActions } from '$/app/pages/residents/residents-reports/resident-reports.actions';
import { EffectHelpersService } from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ResidentReportsApiActions } from './resident-reports.actions';
import { ResidentReportService } from './resident-reports.service';

@Injectable()
export class ResidentReportsEffects {
  private readonly residentReportsService = inject(ResidentReportService);
  private readonly actions$ = inject(Actions);
  private readonly effectHelpers = inject(EffectHelpersService);

  loadResidentReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.loadResidentReports),
      this.effectHelpers.apiRequest({
        description: 'Load Resident Reports',
        useMapOperator: 'switchMap',
        onRequest: (action) =>
          this.residentReportsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.residentReportsService, action?.params?.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentReports',
            response,
            ResidentReportsApiActions.loadResidentReportsSuccess
          );
          return responseData.getActions();
        },
        onError: (error) =>
          ResidentReportsApiActions.loadResidentReportsFail({
            error
          })
      })
    );
  });

  createResidentReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.createResidentReport),
      this.effectHelpers.apiRequest({
        description: 'Create Resident Report',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.residentReportsService.create(action.data, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'residentReports',
            response,
            ResidentReportsApiActions.createResidentReportSuccess,
            {
              payloadKey: 'residentReport'
            }
          );

          return responseData.getActions();
        },
        onError: (error) =>
          ResidentReportsApiActions.createResidentReportFail({
            error
          })
      })
    );
  });

  updateResidentReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.updateResidentReport),
      this.effectHelpers.apiRequest({
        description: 'Update Resident Report',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.residentReportsService.patch(
            action.id,
            action.changes,
            action.params
          ),
        onSuccess: (response) =>
          ResidentReportsApiActions.updateResidentReportSuccess({
            residentReport: response
          }),
        onError: (error) =>
          ResidentReportsApiActions.updateResidentReportFail({
            error
          })
      })
    );
  });

  deleteResidentReports$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ResidentReportsPageActions.deleteResidentReport),
      this.effectHelpers.apiRequest({
        description: 'Delete Resident Report',
        useMapOperator: 'exhaustMap',
        onRequest: (action) =>
          this.residentReportsService.delete(action.id, action.params),
        onSuccess: (response) =>
          ResidentReportsApiActions.deleteResidentReportSuccess({
            id: response.id
          }),
        onError: (error) =>
          ResidentReportsApiActions.deleteResidentReportFail({
            error
          })
      })
    );
  });
}
