import { AbstractApiService } from '$/app/services';
import { FeathersService } from '$/app/services/feathers.service';
import { IResidentReport } from '$shared/services/resident-reports/resident-report.schema';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ResidentReportsWsActions } from './resident-reports.actions';

@Injectable({ providedIn: 'root' })
export class ResidentReportService extends AbstractApiService<IResidentReport> {
  constructor(feathers: FeathersService, store: Store) {
    super('resident-reports', feathers, store, {
      entityName: 'residentReport',
      created: ResidentReportsWsActions.residentReportsCreated,
      patched: ResidentReportsWsActions.residentReportsPatched,
      removed: ResidentReportsWsActions.residentReportsRemoved
    });
  }
}
