import { selectFacilityTime } from '$/app/store/facility-settings/facility-settings.selectors';
import { Logger } from '$shared/logger';
import {
  DateRangePreset,
  getDateRangePresetDictionary
} from '$shared/services/filters/date-range-filter/date-range-presets';
import { Dictionary } from '$shared/types';
import { FacilityTime } from '$shared/utils/date-time/facility-time';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { intersection, isEmpty, isNil, without } from 'lodash';
import { Info } from 'luxon';

export type DateRangePresetOptions = {
  year: number;
  includePresets?: string[];
  excludePresets?: string[];
};

@Injectable({ providedIn: 'root' })
export class FacilityTimeService extends FacilityTime {
  constructor(store: Store) {
    super('UTC');

    store.select(selectFacilityTime).subscribe((ft) => {
      this._timezone = ft.timezone;
    });
  }

  getPresetDictionary(
    year = this.createDateTime().year
  ): Dictionary<DateRangePreset> {
    return getDateRangePresetDictionary(year, this);
  }

  generateDateRangePresets(options: DateRangePresetOptions): DateRangePreset[] {
    const currentYear = this.createDateTime().year;

    const months = Info.months('long', { locale: 'en-US' });

    const year = options?.year || currentYear;

    const defaultPresets = [
      'next7Days',
      'tomorrow',
      'today',
      'yesterday',
      'thisMonth',
      'nextMonth',
      'past7Days',
      'past30Days',
      'past60Days',
      'past90Days',
      'q1',
      'q2',
      'q3',
      'q4',
      ...months.map((m) => m.toLowerCase())
    ];

    Logger.assert(
      isNil(options?.includePresets) || isNil(options.excludePresets),
      'Cannot specify both included and excluded presets',
      options
    );

    const includedPresets = !isEmpty(options?.includePresets)
      ? intersection(defaultPresets, options.includePresets)
      : !isEmpty(options?.excludePresets)
        ? without(defaultPresets, ...options.excludePresets)
        : defaultPresets;

    const presetsDictionary = this.getPresetDictionary(year);

    const presets = includedPresets
      .filter((preset) => {
        const currentYearPresets = [
          'today',
          'yesterday',
          'past7Days',
          'past30Days',
          'past60Days',
          'past90Days'
        ];
        // Presets for current year
        if (currentYearPresets.includes(preset) && year !== currentYear) {
          return false;
        }

        return true;
      })
      .map((preset) => {
        return presetsDictionary[preset];
      });

    return presets;
  }
}
