import { AuthenticationActions } from '$/app/authentication/authentication.actions';
import { AuthenticationService } from '$/app/authentication/authentication.service';
import { DocumentsPortalFormShellPageActions } from '$/app/pages/documents/documents-portal/documents-portal-form-shell/documents-portal-form-shell.page.actions';
import { DocumentsPortalOverviewPageActions } from '$/app/pages/documents/documents-portal/documents-portal-overview/documents-portal-overview.actions';
import { UserProfilePageActions } from '$/app/pages/user-profile/user-profile.actions';
import { AppInfo, FormsApiService, MixpanelService } from '$/app/services';
import { UserApiService } from '$/app/services/api/user.service';
import { ApiData, SettingsValues } from '$/app/utils';
import { Logger } from '$shared/logger';
import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { PushNotifications } from '@capacitor/push-notifications';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular';
import { isEmpty } from 'lodash';
import { EMPTY, firstValueFrom, from, of } from 'rxjs';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  mergeMap,
  switchMap,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import { RouterSelectors } from '../router';
import { UserApiActions } from './actions/user-api.actions';
import { UserSelectors } from './user.selectors';

@Injectable()
export class UserEffects {
  private readonly store = inject<any>(Store);
  private readonly router = inject(Router);
  private readonly actions$ = inject(Actions);
  private readonly mixpanel = inject(MixpanelService);
  private readonly userService = inject(UserApiService);
  private readonly formsService = inject(FormsApiService);
  private readonly authenticationService = inject(AuthenticationService);

  private readonly currentRouteData = this.store.selectSignal(
    RouterSelectors.selectData
  );

  $authenticated = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          AuthenticationActions.signupSuccess,
          AuthenticationActions.loginSuccess,
          AuthenticationActions.authenticateWithStoredTokenSuccess,
          AuthenticationActions.authenticateFacilityChangeSuccess
        ),
        exhaustMap((action) => {
          const { loginData, params } = action;

          return from(this.authenticationService.authenticated(loginData)).pipe(
            map(() => {
              if (params.onComplete) {
                params.onComplete();
              }

              return EMPTY;
            }),
            catchError((error) => {
              Logger.error('Error initializing after authentication', error);
              if (params.onComplete) {
                params.onComplete();
              }

              return EMPTY;
            })
          );
        })
      );
    },
    { dispatch: false }
  );

  logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthenticationActions.logout),
        tap(() => {
          SettingsValues.ACCESS_TOKEN = null;

          this.mixpanel.reset();

          Sentry.setUser(null);
          Sentry.setTag('facilityId', null);
          Sentry.setTag('orgId', null);

          if (this.currentRouteData()?.noAuth) {
            return;
          }

          SettingsValues.commitChanges().finally(() => {
            this.router.navigateByUrl('/login');
          });
        })
      );
    },
    { dispatch: false }
  );

  updateUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfilePageActions.updateUser),
      exhaustMap((action) => {
        return this.userService
          .patch(action.id, action.changes, action.params)
          .pipe(
            map((user) => UserApiActions.updateUserSuccess({ user })),
            catchError((error) => of(UserApiActions.updateUserFail({ error })))
          );
      })
    );
  });

  registerPushNotifications$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          UserProfilePageActions.registerPushNotifications,
          UserProfilePageActions.updateUser
        ),
        tap(async (action) => {
          // TODO(2024-03-27): Extract this code into a separate function that
          // can also be called by initializationService.authorized
          if (AppInfo.deviceInfo.platform === 'web') {
            return;
          }

          const userId = this.getPushNotificationUserId(action);

          if (!userId) {
            return Logger.error(
              'User ID not found when registering for push notifications'
            );
          }

          let status = await PushNotifications.checkPermissions();

          if (status.receive === 'prompt') {
            status = await PushNotifications.requestPermissions();
          }

          if (status.receive !== 'granted') {
            return Logger.warn(
              'Permission to receive push notifications not granted'
            );
          }

          try {
            await PushNotifications.register();
          } catch (error) {
            Logger.error('Failed to register FCM token', { error });
          }
        })
      );
    },
    { dispatch: false }
  );

  private getPushNotificationUserId(action: any) {
    switch (action.type) {
      case UserProfilePageActions.updateUser.type:
        return action.id;

      case AuthenticationActions.loginSuccess.type:
      case AuthenticationActions.authenticateWithStoredTokenSuccess.type:
      case AuthenticationActions.authenticateFacilityChangeSuccess.type:
        return action.loginData.user.id;

      case UserProfilePageActions.registerPushNotifications.type:
        return action.userId;
    }

    return undefined;
  }

  saveFCMToken$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserApiActions.saveFCMToken),
        tap((action) => {
          if (!action.userId) {
            return Logger.error('User ID not found when saving FCM token', {
              action
            });
          }

          if (!action.fcmToken) {
            return Logger.error('FCM token not found when saving FCM token', {
              action
            });
          }

          SettingsValues.FCM_TOKEN = action.fcmToken;

          return this.userService
            .patch(
              action.userId,
              {},
              {
                query: {
                  $actions: [
                    { registerFcmToken: { fcmToken: action.fcmToken } }
                  ]
                }
              }
            )
            .pipe(
              catchError((error) => {
                Logger.error('Failed to register FCM token', { error });
                return of(error);
              })
            );
        })
      );
    },
    { dispatch: false }
  );

  unregisterPushNotifications$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(
          UserProfilePageActions.unregisterPushNotifications,
          AuthenticationActions.logout
        ),
        tap(async (action) => {
          const fcmToken = SettingsValues.FCM_TOKEN;

          if (!fcmToken) {
            return;
          }

          if (!action.userId) {
            return Logger.warn(
              'User ID not found when unregistering for push notifications',
              { action }
            );
          }

          try {
            await firstValueFrom(
              this.userService.patch(
                action.userId,
                {},
                { query: { $actions: [{ unregisterFcmToken: { fcmToken } }] } }
              )
            );

            SettingsValues.FCM_TOKEN = null;
          } catch (error) {
            Logger.error('Failed to unregister FCM token', { error });
          }
        })
      );
    },
    { dispatch: false }
  );

  // TODO(2024-02-01): brane: There is currently no representation of the user
  // in the store for external users. This is a temporary solution until
  // a more permanent authentication strategy is implemented.
  getExternalUserUsingAccessToken$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentsPortalOverviewPageActions.loadDocuments,
        DocumentsPortalFormShellPageActions.fetchDocument
      ),
      withLatestFrom(this.store.select(UserSelectors.selectUser)),
      filter(
        ([action, user]) => isEmpty(user) && !!action.params.query?.accessToken
      ),
      switchMap(([action]) => {
        return this.formsService
          .getAll({
            query: {
              accessToken: action.params.query.accessToken,
              $actions: [{ getUserOnly: true }]
            }
          })
          .pipe(
            mergeMap((users: any) => {
              const responseData = new ApiData(
                'users',
                users[0],
                UserApiActions.fetchUserSuccess,
                { payloadKey: 'user' }
              );

              return responseData.getActions();
            }),
            catchError((error) => {
              return of(UserApiActions.fetchUserFail({ error }));
            })
          );
      })
    )
  );
}
