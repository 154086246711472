<form [formGroup]="form" class="{{ 'theme' | alcTheme }}">
  <div class="mb-6">
    <mat-radio-group
      aria-labelledby="type-radio-group"
      class="flex flex-col {{ 'theme' | alcTheme }}"
      [color]="color()"
      formControlName="type"
      (change)="onTypeChange($event)"
    >
      <mat-radio-button [color]="color()" class="m-0" value="preset">
        Preset Range
      </mat-radio-button>

      <mat-radio-button [color]="color()" class="m-0" value="custom">
        Custom Range
      </mat-radio-button>
    </mat-radio-group>
  </div>

  @if (form.get('type').value === 'preset') {
    <mat-form-field class="form-input-stretch">
      <mat-label>Year</mat-label>

      <mat-select
        formControlName="year"
        (selectionChange)="onYearChange($event)"
      >
        @for (year of years; track year) {
          <mat-option [value]="year">
            {{ year }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (form.get('type').value === 'preset') {
    <mat-form-field class="form-input-stretch">
      <mat-label>Preset</mat-label>
      <mat-select
        formControlName="preset"
        panelClass="date-range-presets"
        (selectionChange)="onPresetChange($event)"
      >
        @for (preset of presets; track preset.id) {
          <mat-option [value]="preset.id">
            {{ preset.name }}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  }

  @if (form.get('type').value === 'custom') {
    <alc-date-input label="Start Date" formControlName="startDate" />
  }

  @if (form.get('type').value === 'custom') {
    <alc-date-input label="End Date" formControlName="endDate" />
  }
</form>
