import { Logger } from '$shared/logger';
import { GenderId } from '$shared/lookups/genders.lookup';
import { parsePersonName } from '$shared/utils/parsers/parse-person-name';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  input,
  model
} from '@angular/core';
import { isEmpty } from 'lodash';
import sha256 from 'tiny-hashes/sha256';
import { AlcCommonModule } from '../../alc-common.module';

@Component({
  selector: 'alc-avatar',
  imports: [AlcCommonModule],
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlcAvatarComponent {
  readonly type = input<'employee' | 'resident'>('employee');
  readonly gender = input<GenderId>(undefined);
  readonly url = model<string>(undefined);
  readonly name = input<string>('');
  readonly size = input<number | string>(40);

  protected readonly src = computed(() => {
    const size = Number(this.size());

    Logger.assert(isFinite(size), 'Size must be a number');

    switch (true) {
      case !isEmpty(this.url()):
        return this.url();

      case this.type() === 'employee' && this.gender() === 'male':
      case this.type() === 'employee' && this.gender() === 'female':
      case this.type() === 'resident' && this.gender() === 'male':
      case this.type() === 'resident' && this.gender() === 'female':
        return `/assets/avatars/avatar-${this.gender()}-${this.type()}.svg`;

      default:
        return '/assets/icon/material/account_circle.svg';
    }
  });

  protected readonly hslColor = computed(() => {
    if (!this.name()) {
      return '';
    }
    const saturation = '100%';
    const lightness = '48%';

    const hash = sha256(this.name());

    const decimal = parseInt(hash.substring(0, 6), 16);

    const hue = decimal % 360;

    return `hsl(${hue}, ${saturation}, ${lightness})`;
  });

  protected readonly initials = computed(() => {
    const { initials } = parsePersonName(this.name());
    return initials;
  });
}
