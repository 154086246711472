import { createStoreActions } from '$/lib/create-store-actions';
import { IResidentReport } from '$shared/services/resident-reports/resident-report.schema';
import { props } from '@ngrx/store';

export const {
  ResidentReportsApiActions,
  ResidentReportsWsActions,
  ResidentReportsGeneralActions
} = createStoreActions(
  'Resident Report',
  'Resident Reports',
  props<{ residentReport: IResidentReport }>(),
  props<{ residentReports: IResidentReport[] }>()
);
