import { IDateRangePickerValue } from '$/app/shared/form-controls/date-range-picker-control/date-range-picker-control.component';
import * as FacilitySettingsSelectors from '$/app/store/facility-settings/facility-settings.selectors';
import * as RouterSelectors from '$/app/store/router/router.selectors';
import { getDateRange } from '$/app/utils';
import { createSelector } from '@ngrx/store';
import { isNil } from 'lodash';
import { DateTime, Interval } from 'luxon';

const selectDateTime = createSelector(
  RouterSelectors.selectQueryParam('date'),
  RouterSelectors.selectQueryParam('time'),
  FacilitySettingsSelectors.selectFacilityTime,
  (date, time, ft): DateTime => {
    if (!date) {
      date = ft.createDateTime().toFormat('yyyy-MM-dd');
    }

    if (time === 'all') {
      time = '00:00 AM';
    }

    const datetime = ft.convertDateTimeKeepingLocalTime(
      `${date} ${time ?? '00:00 am'}`,
      {
        parseFormatNames: ['DATE_SHORT_TIME_12']
      }
    );

    return datetime;
  }
);

const selectDayRange = createSelector(
  RouterSelectors.selectQueryParam('date'),
  RouterSelectors.selectData,
  FacilitySettingsSelectors.selectFacilityTime,
  (date, routeData, ft): Interval => {
    const day: string =
      date || routeData?.queryParams?.date || ft.createDateTime().toISO();

    return Interval.fromDateTimes(
      ft.createDate(day),
      ft.createDate(day).endOf('day')
    );
  }
);

const selectStartDate = createSelector(
  RouterSelectors.selectQueryParam('startDate'),
  RouterSelectors.selectData,
  FacilitySettingsSelectors.selectFacilityTime,
  (startDate, routeData, ft): DateTime => {
    const start: string = startDate || routeData?.queryParams?.startDate;

    return start ? ft.createDate(start) : null;
  }
);

const selectEndDate = createSelector(
  RouterSelectors.selectQueryParam('endDate'),
  RouterSelectors.selectData,
  FacilitySettingsSelectors.selectFacilityTime,
  (endDate, routeData, ft): DateTime => {
    const end: string = endDate || routeData?.queryParams?.endDate;

    return end ? ft.createDate(end).endOf('day') : null;
  }
);

const selectDateRangePickerParams = createSelector(
  RouterSelectors.selectQueryParam('type'),
  RouterSelectors.selectQueryParam('preset'),
  RouterSelectors.selectQueryParam('year'),
  selectStartDate,
  selectEndDate,
  (
    type: 'preset' | 'custom',
    preset: string,
    year: string,
    startDate,
    endDate
  ): IDateRangePickerValue => {
    return {
      type,
      preset,
      year: isNil(year) ? year : +year,
      startDate,
      endDate
    };
  }
);

// TEMPORARY: This selector should replace the previous one, but should contain the previous
// selector's name, but I'm creating this one here to avoid breaking the existing code and having
// everything right now.
const selectDateRangePickerParamsWithDefault = (
  defaultPreset: string = 'past7Days'
) => {
  return createSelector(
    selectDateRangePickerParams,
    FacilitySettingsSelectors.selectFacilityTime,
    (params, ft): IDateRangePickerValue =>
      getDateRange(params, ft, defaultPreset)
  );
};

export const RouterQuerySelectors = {
  selectDateTime,
  selectDayRange,
  selectDateRangePickerParams,
  selectDateRangePickerParamsWithDefault
};
