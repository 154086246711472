// import { Table } from '$/models/data/base';
// import { CountryId } from '$shared/lookups/countries.lookup';
// import { FacilityTypeId } from '$shared/lookups/facility-types.lookup';
// import { StateId } from '$shared/lookups/states.lookup';

// export class Facility extends Table {
//   orgId: string;
//   name: string;
//   displayName?: string;
//   type: FacilityTypeId;
//   regionalOffice?: string;
//   facilityNumber?: number;
//   capacity: number;
//   address1?: string;
//   address2?: string;
//   city?: string;
//   state: StateId;
//   postalCode?: string;
//   country: CountryId;
//   residents?;
//   phoneNumbers?: any[];
//   isActive?: boolean;
//   isDiscounted?: boolean;
//   logoFileId?: string | null;
// }

// TEMPORARY: This should really be imported directly throughout the frontend
// but this was a quick way to get the fontend to build without a huge refactor.
export { Facility } from '$shared/services/facility.schema';
