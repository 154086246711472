import { DocumentDetailsFormPageActions } from '$/app/pages/documents/document-details/document-details-form/document-details-form.actions';
import { DocumentDetailsInfoPageActions } from '$/app/pages/documents/document-details/document-details-info/document-details-info.actions';
import { DocumentPacketsPageActions } from '$/app/pages/documents/document-packets/document-packets.actions';
import { DocumentTrackingPageActions } from '$/app/pages/documents/document-tracking/document-tracking.actions';
import { DocumentsOverviewPageActions } from '$/app/pages/documents/documents-overview/documents-overview.actions';
import { DocumentsPortalFormShellPageActions } from '$/app/pages/documents/documents-portal/documents-portal-form-shell/documents-portal-form-shell.page.actions';
import { DocumentsPortalOverviewPageActions } from '$/app/pages/documents/documents-portal/documents-portal-overview/documents-portal-overview.actions';
import { DocumentActionMenuActions } from '$/app/pages/documents/shared/components/document-action-menu/document-action-menu.actions';
import {
  DocumentsApiService,
  EffectHelpersService,
  FormsApiService
} from '$/app/services';
import { ApiData, getAllPages, reducePaginatedResponses } from '$/app/utils';
import { readFileAsDataUrl } from '$/app/utils/files';
import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { set } from 'lodash';
import { DocumentsApiActions } from './documents.actions';

@Injectable()
export class DocumentsEffects {
  private readonly effectHelpers = inject(EffectHelpersService);
  private readonly actions$ = inject(Actions);
  private readonly documentsService = inject(DocumentsApiService);
  private readonly formsService = inject(FormsApiService);

  // TODO: Consider wrapping the entire "createEffect" into "apiRequest"
  // The advantage would be in clarity, as the description could be right at the top
  // The "ofType" could be folded into the "apiRequest" as well
  // NOTE: We could go a step further, and standardize the service method signatures
  // Then, it could be:
  // apiRequestEffect({
  //   description: 'loading˝ documents',
  //   service: this.documentsService,
  //   method: 'getAll',
  //   ... etc
  loadDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentTrackingPageActions.loadDocuments),
      this.effectHelpers.apiRequest({
        description: 'loading documents',
        onRequest: (action) =>
          // TODO: This could be simplified by having getAllPages
          // be a utility function, rather than an operator.
          this.documentsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.documentsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'documents',
            response,
            DocumentsApiActions.loadDocumentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => DocumentsApiActions.loadDocumentsFail({ error })
      })
    )
  );

  getDocuments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentsOverviewPageActions.getDocuments,
        DocumentPacketsPageActions.getDocuments
      ),
      this.effectHelpers.apiRequest({
        description: 'loading documents',
        onRequest: (action) =>
          // TODO: This could be simplified by having getAllPages
          // be a utility function, rather than an operator.
          this.documentsService
            .getAll(action.params)
            .pipe(
              getAllPages(this.documentsService, action.params.query),
              reducePaginatedResponses()
            ),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'documents',
            response,
            DocumentsApiActions.getDocumentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => DocumentsApiActions.getDocumentsFail({ error })
      })
    )
  );

  fetchDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentDetailsInfoPageActions.fetchDocument),
      this.effectHelpers.apiRequest({
        description: 'fetching a document',
        onRequest: (action) =>
          this.documentsService.get(action.id, action.params),
        onSuccess: (document) => {
          const responseData = new ApiData(
            'documents',
            document,
            DocumentsApiActions.fetchDocumentSuccess,
            {
              payloadKey: 'document',
              singleRecord: false
            }
          );

          return responseData.getActions();
        },
        onError: (error) => DocumentsApiActions.fetchDocumentFail({ error })
      })
    )
  );

  createDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentTrackingPageActions.createDocumentFromTemplate,
        DocumentTrackingPageActions.createDocument,
        DocumentPacketsPageActions.createDocumentFromTemplate,
        DocumentsOverviewPageActions.createDocument
      ),
      this.effectHelpers.apiRequest({
        description: 'creating a document',
        onRequest: (action) =>
          this.documentsService.create(action.document, action.params),
        useMapOperator: 'exhaustMap',
        onSuccess: (document) =>
          DocumentsApiActions.createDocumentSuccess({ document }),
        onError: (error) => DocumentsApiActions.createDocumentFail({ error })
      })
    )
  );

  updateDocument$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentDetailsInfoPageActions.updateDocument,
        DocumentDetailsFormPageActions.updateDocumentForm,
        DocumentActionMenuActions.removeFile,
        DocumentActionMenuActions.renewDocument
      ),
      this.effectHelpers.apiRequest({
        description: 'updating document',
        onRequest: (action) =>
          this.documentsService.patch(action.id, action.changes, action.params),
        onSuccess: (document) =>
          DocumentsApiActions.updateDocumentSuccess({ document }),
        onError: (error) => DocumentsApiActions.updateDocumentFail({ error })
      })
    )
  );

  deleteDocument$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(DocumentActionMenuActions.deleteDocument),
      this.effectHelpers.apiRequest({
        description: 'Delete document',
        useMapOperator: 'exhaustMap',
        onRequest: (action) => {
          return this.documentsService.delete(action.id, action.params);
        },
        onSuccess: (document) =>
          DocumentsApiActions.deleteDocumentSuccess({ id: document.id }),
        onError: (error) => DocumentsApiActions.deleteDocumentFail({ error })
      })
    );
  });

  uploadFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        DocumentActionMenuActions.uploadFile,
        DocumentDetailsInfoPageActions.uploadFile
      ),
      this.effectHelpers.apiRequest({
        description: 'uploading file and attaching to document',
        transformAction: async (action) =>
          set(action, 'params.query.$actions', [
            {
              uploadAttachedFile: {
                url: await readFileAsDataUrl(action.file),
                filename: action.file.name
              }
            }
          ]),
        onRequest: (action) =>
          this.documentsService.patch(action.id, action.changes, action.params),
        onSuccess: (document) =>
          DocumentsApiActions.updateDocumentSuccess({ document }),
        onError: (error) => DocumentsApiActions.updateDocumentFail({ error })
      })
    )
  );

  loadDocumentsForDocumentsPortal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsPortalOverviewPageActions.loadDocuments),
      this.effectHelpers.apiRequest({
        description: 'loading documents portal overview',
        onRequest: (action) => this.formsService.getAll(action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'documents',
            response,
            DocumentsApiActions.loadDocumentsSuccess
          );

          return responseData.getActions();
        },
        onError: (error) => DocumentsApiActions.loadDocumentsFail({ error })
      })
    )
  );

  fetchDocumentForDocumentPortal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsPortalFormShellPageActions.fetchDocument),
      this.effectHelpers.apiRequest({
        description: 'loading documents portal overview',
        onRequest: (action) => this.formsService.get(action.id, action.params),
        onSuccess: (response) => {
          const responseData = new ApiData(
            'documents',
            response,
            DocumentsApiActions.fetchDocumentSuccess,
            {
              payloadKey: 'document',
              singleRecord: false
            }
          );

          return responseData.getActions();
        },
        onError: (error) => DocumentsApiActions.fetchDocumentFail({ error })
      })
    )
  );

  updateDocumentForDocumentPortal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DocumentsPortalFormShellPageActions.updateDocumentForm),
      this.effectHelpers.apiRequest({
        description: 'updating document form',
        onRequest: (action) =>
          this.formsService.patch(action.id, action.changes, action.params),
        onSuccess: (document) =>
          DocumentsApiActions.updateDocumentSuccess({ document }),
        onError: (error) => DocumentsApiActions.updateDocumentFail({ error })
      })
    )
  );
}
