import { InitializationActions } from '$/app/initialization/initialization.actions';
import { AcknowledgementsGeneralActions } from '$/app/store/acknowledgements';
import { ActivitiesGeneralActions } from '$/app/store/activities/activities.actions';
import { ActivityLogsGeneralActions } from '$/app/store/activity-logs';
import { ActivityResidentsGeneralActions } from '$/app/store/activity-residents/activity-residents.actions';
import { AllergiesGeneralActions } from '$/app/store/allergies/allergies.actions';
import { AnnouncementRepliesGeneralActions } from '$/app/store/announcement-replies/announcement-replies.actions';
import { AnnouncementsGeneralActions } from '$/app/store/announcements/announcements.actions';
import { AppointmentsGeneralActions } from '$/app/store/appointments/actions/appointments-gen.actions';
import { BehaviorLogsGeneralActions } from '$/app/store/behavior-logs/behavior-logs.actions';
import { BehaviorsGeneralActions } from '$/app/store/behaviors/behaviors.actions';
import { BloodPressureLogsGeneralActions } from '$/app/store/blood-pressure-logs/blood-pressure-logs.actions';
import { BloodSugarLogsGeneralActions } from '$/app/store/blood-sugar-logs/blood-sugar-logs.actions';
import { BodyCheckLogsGeneralActions } from '$/app/store/body-check-logs';
import { BodyCheckObservationsGeneralActions } from '$/app/store/body-check-observations/body-check-observations.actions';
import { BmLogsGeneralActions } from '$/app/store/bowel-movement-logs/bowel-movement-logs.actions';
import { CarePlanItemSchedulesGeneralActions } from '$/app/store/care-plan-item-schedules/care-plan-item-schedules.actions';
import { CarePlanItemsGeneralActions } from '$/app/store/care-plan-items/care-plan-items.actions';
import { CareTasksGeneralActions } from '$/app/store/care-tasks/care-tasks.actions';
import { DestructionMethodsGeneralActions } from '$/app/store/destruction-methods';
import { DiagnosesGeneralActions } from '$/app/store/diagnoses/diagnoses.actions';
import { DietsGeneralActions } from '$/app/store/diets/diets.actions';
import { DocumentPacketDocumentsGeneralActions } from '$/app/store/document-packet-documents/document-packet-documents.actions';
import { DocumentPacketsGeneralActions } from '$/app/store/document-packets/document-packets.actions';
import { DocumentParticipantsGeneralActions } from '$/app/store/document-participants/document-participants.actions';
import { DocumentRelationsGeneralActions } from '$/app/store/document-relations/document-relations.actions';
import { DocumentTemplatePacketTemplatesGeneralActions } from '$/app/store/document-template-packet-templates/document-template-packet-templates.actions';
import { DocumentTemplatePacketsGeneralActions } from '$/app/store/document-template-packets/document-template-packets.actions';
import { DocumentTemplatesGeneralActions } from '$/app/store/document-templates/document-templates.actions';
import { DocumentsGeneralActions } from '$/app/store/documents/documents.actions';
import { FacilitiesGeneralActions } from '$/app/store/facilities/facilities.actions';
import { FacilityPhonesGeneralActions } from '$/app/store/facility-phones/actions';
import { FacilitySettingsGeneralActions } from '$/app/store/facility-settings/actions';
import { FacilityUserGroupMembersGeneralActions } from '$/app/store/facility-user-group-members/actions';
import { FacilityUserGroupsGeneralActions } from '$/app/store/facility-user-groups/actions';
import { FacilityUserPhonesGeneralActions } from '$/app/store/facility-user-phones/actions';
import { FacilityUserRolesGeneralActions } from '$/app/store/facility-user-roles/facility-user-roles-api.actions';
import { FacilityUsersGeneralActions } from '$/app/store/facility-users/actions';
import { FilesGeneralActions } from '$/app/store/files';
import { FoodLogsGeneralActions } from '$/app/store/food-logs/actions';
import { IncidentReportResidentsGeneralActions } from '$/app/store/incident-report-residents';
import { IncidentReportsGeneralActions } from '$/app/store/incident-reports';
import { InstalledLibraryCollectionsGeneralActions } from '$/app/store/installed-library-collections/installed-library-collections.actions';
import { LibraryCollectionDocumentTemplatePacketsGeneralActions } from '$/app/store/library-collection-document-template-packets/actions';
import { LibraryCollectionDocumentTemplatesGeneralActions } from '$/app/store/library-collection-document-templates/actions';
import { LibraryCollectionsGeneralActions } from '$/app/store/library-collections/library-collections.actions';
import { LibraryDocumentTemplatePacketTemplatesGeneralActions } from '$/app/store/library-document-template-packet-templates/actions';
import { LibraryDocumentTemplatePacketsGeneralActions } from '$/app/store/library-document-template-packets/actions';
import { LibraryDocumentTemplatesGeneralActions } from '$/app/store/library-document-templates/actions';
import { MedicalEventsGeneralActions } from '$/app/store/medical-events/actions';
import { MedicalHistoriesGeneralActions } from '$/app/store/medical-histories/actions';
import { MedicalProfessionalPhonesGeneralActions } from '$/app/store/medical-professional-phones/actions';
import { MedicalProfessionalsGeneralActions } from '$/app/store/medical-professionals/actions';
import { MedicationDestructionItemsGeneralActions } from '$/app/store/medication-destruction-items/medication-destruction-items.actions';
import { MedicationDestructionsGeneralActions } from '$/app/store/medication-destructions/medication-destructions.actions';
import { MedicationInventoryItemCountsGeneralActions } from '$/app/store/medication-inventory-item-counts/medication-inventory-item-counts.actions';
import { MedicationInventoryItemsGeneralActions } from '$/app/store/medication-inventory-items/actions';
import { MedicationMarsGeneralActions } from '$/app/store/medication-mars/medication-mars.actions';
import { MedicationOrderItemsGeneralActions } from '$/app/store/medication-order-items/medication-order-items.actions';
import { MedicationOrdersGeneralActions } from '$/app/store/medication-orders/medication-orders.actions';
import { MedicationSchedulesGeneralActions } from '$/app/store/medication-schedules/actions';
import { MedicationTaskAmountsGeneralActions } from '$/app/store/medication-task-amounts/medication-task-amounts.actions';
import { MedicationTasksGeneralActions } from '$/app/store/medication-tasks/medication-tasks.actions';
import { MedicationsGeneralActions } from '$/app/store/medications/actions';
import { MoodLogsGeneralActions } from '$/app/store/mood-logs/actions';
import { NotificationsGeneralActions } from '$/app/store/notifications';
import { OmittedAmountsGeneralActions } from '$/app/store/omitted-amounts/omitted-amounts.actions';
import { OmittedMedicationsGeneralActions } from '$/app/store/omitted-medications/actions';
import { OrganizationsGeneralActions } from '$/app/store/organizations/organizations.actions';
import { OxygenLogsGeneralActions } from '$/app/store/oxygen-logs';
import { PharmaciesGeneralActions } from '$/app/store/pharmacies/actions';
import { PharmacyApiMessagesGeneralActions } from '$/app/store/pharmacy-api-messages';
import { PharmacyPhonesGeneralActions } from '$/app/store/pharmacy-phones/actions';
import { PositionLogsGeneralActions } from '$/app/store/position-logs';
import { PrnAmountsGeneralActions } from '$/app/store/prn-amounts';
import { PrnsGeneralActions } from '$/app/store/prns/actions';
import { ProposedChangesGeneralActions } from '$/app/store/proposed-changes';
import { ResidentContactPhonesGeneralActions } from '$/app/store/resident-contact-phones';
import { ResidentContactsGeneralActions } from '$/app/store/resident-contacts/actions';
import { ResidentDietsGeneralActions } from '$/app/store/resident-diets/resident-diets.actions';
import { ResidentGroupMembersGeneralActions } from '$/app/store/resident-group-members/actions/resident-group-members-gen.actions';
import { ResidentGroupsGeneralActions } from '$/app/store/resident-groups/actions';
import { ResidentInsurancesGeneralActions } from '$/app/store/resident-insurances/resident-insurances.actions';
import { ResidentLogTypesGeneralActions } from '$/app/store/resident-log-types';
import { ResidentMedicalProfessionalsGeneralActions } from '$/app/store/resident-medical-professionals/actions';
import { ResidentReportsGeneralActions } from '$/app/store/resident-reports/resident-reports.actions';
import { ResidentsGeneralActions } from '$/app/store/residents/actions';
import { RolePermissionsGeneralActions } from '$/app/store/role-permissions/actions';
import { RolesGeneralActions } from '$/app/store/roles/actions';
import { RoomsGeneralActions } from '$/app/store/rooms/rooms.actions';
import { SeizureLogSymptomsGeneralActions } from '$/app/store/seizure-log-symptoms';
import { SeizureLogsGeneralActions } from '$/app/store/seizure-logs';
import { ShiftNotesGeneralActions } from '$/app/store/shift-notes/actions';
import { ShowerLogsGeneralActions } from '$/app/store/shower-logs';
import { SleepLogsGeneralActions } from '$/app/store/sleep-logs';
import { SymptomsGeneralActions } from '$/app/store/symptoms';
import { TemperatureLogsGeneralActions } from '$/app/store/temperature-logs/temperature-logs.actions';
import { UrineLogsGeneralActions } from '$/app/store/urine-logs/urine-logs.actions';
import { UserGeneralActions } from '$/app/store/user/actions/user-api.actions';
import { WaterLogsGeneralActions } from '$/app/store/water-logs/actions';
import { WeightLogsGeneralActions } from '$/app/store/weight-logs/actions';

// prettier-ignore

export const EntityActionMap = {
  activities: ActivitiesGeneralActions.addActivities,
  activityLogs: ActivityLogsGeneralActions.addActivityLogs,
  activityResidents: ActivityResidentsGeneralActions.addActivityResidents,
  acknowledgements: AcknowledgementsGeneralActions.addAcknowledgements,
  allergies: AllergiesGeneralActions.addAllergies,
  announcementReplies: AnnouncementRepliesGeneralActions.addAnnouncementReplies,
  announcements: AnnouncementsGeneralActions.addAnnouncements,
  appointments: AppointmentsGeneralActions.addAppointments,
  behaviorLogs: BehaviorLogsGeneralActions.addBehaviorLogs,
  behaviors: BehaviorsGeneralActions.addBehaviors,
  bloodPressureLogs: BloodPressureLogsGeneralActions.addBloodPressureLogs,
  bloodSugarLogs: BloodSugarLogsGeneralActions.addBloodSugarLogs,
  bmLogs: BmLogsGeneralActions.addBmLogs,
  bodyCheckLogs: BodyCheckLogsGeneralActions.addBodyCheckLogs,
  bodyCheckObservations: BodyCheckObservationsGeneralActions.addBodyCheckObservations,
  carePlanItemSchedules: CarePlanItemSchedulesGeneralActions.addCarePlanItemSchedules,
  carePlanItems: CarePlanItemsGeneralActions.addCarePlanItems,
  careTasks: CareTasksGeneralActions.addCareTasks,
  destructionMethods: DestructionMethodsGeneralActions.addDestructionMethods,
  diets: DietsGeneralActions.addDiets,
  documentPacketDocuments: DocumentPacketDocumentsGeneralActions.addDocumentPacketDocuments,
  documentPackets: DocumentPacketsGeneralActions.addDocumentPackets,
  documentTemplatePacketTemplates: DocumentTemplatePacketTemplatesGeneralActions.addDocumentTemplatePacketTemplates,
  documentTemplatePackets: DocumentTemplatePacketsGeneralActions.addDocumentTemplatePackets,
  documentParticipants: DocumentParticipantsGeneralActions.addDocumentParticipants,
  documentRelations: DocumentRelationsGeneralActions.addDocumentRelations,
  documentTemplates: DocumentTemplatesGeneralActions.addDocumentTemplates,
  documents: DocumentsGeneralActions.addDocuments,
  diagnoses: DiagnosesGeneralActions.addDiagnoses,
  facilities: FacilitiesGeneralActions.addFacilities,
  facilityPhones: FacilityPhonesGeneralActions.addFacilityPhones,
  facilitySettings: FacilitySettingsGeneralActions.addFacilitySettings,
  facilityUserGroupMembers: FacilityUserGroupMembersGeneralActions.addFacilityUserGroupMembers,
  facilityUserGroups: FacilityUserGroupsGeneralActions.addFacilityUserGroups,
  facilityUserPhones: FacilityUserPhonesGeneralActions.addFacilityUserPhones,
  facilityUserRoles: FacilityUserRolesGeneralActions.addFacilityUserRoles,
  facilityUsers: FacilityUsersGeneralActions.addFacilityUsers,
  files: FilesGeneralActions.addFiles,
  foodLogs: FoodLogsGeneralActions.addFoodLogs,
  incidentReportResidents: IncidentReportResidentsGeneralActions.addIncidentReportResidents,
  incidentReports: IncidentReportsGeneralActions.addIncidentReports,
  initializationData: InitializationActions.getInitializationDataSuccess,
  installedLibraryCollections: InstalledLibraryCollectionsGeneralActions.addInstalledLibraryCollections,
  libraryCollectionDocumentTemplatePackets: LibraryCollectionDocumentTemplatePacketsGeneralActions.addLibraryCollectionDocumentTemplatePackets,
  libraryCollectionDocumentTemplates: LibraryCollectionDocumentTemplatesGeneralActions.addLibraryCollectionDocumentTemplates,
  libraryCollections: LibraryCollectionsGeneralActions.addLibraryCollections,
  libraryDocumentTemplatePacketTemplates: LibraryDocumentTemplatePacketTemplatesGeneralActions.addLibraryDocumentTemplatePacketTemplates,
  libraryDocumentTemplatePackets: LibraryDocumentTemplatePacketsGeneralActions.addLibraryDocumentTemplatePackets,
  libraryDocumentTemplates: LibraryDocumentTemplatesGeneralActions.addLibraryDocumentTemplates,
  medicationDestructionItems: MedicationDestructionItemsGeneralActions.addMedicationDestructionItems,
  medicationDestructions: MedicationDestructionsGeneralActions.addMedicationDestructions,
  medicalEvents: MedicalEventsGeneralActions.addMedicalEvents,
  medicalHistories: MedicalHistoriesGeneralActions.addMedicalHistories,
  medicalProfessionalPhones: MedicalProfessionalPhonesGeneralActions.addMedicalProfessionalPhones,
  medicalProfessionals: MedicalProfessionalsGeneralActions.addMedicalProfessionals,
  medicationInventoryItemCounts: MedicationInventoryItemCountsGeneralActions.addMedicationInventoryItemCounts,
  medicationInventoryItems: MedicationInventoryItemsGeneralActions.addMedicationInventoryItems,
  medicationMars: MedicationMarsGeneralActions.addMedicationMars,
  medicationOrderItems: MedicationOrderItemsGeneralActions.addMedicationOrderItems,
  medicationOrders: MedicationOrdersGeneralActions.addMedicationOrders,
  medicationSchedules: MedicationSchedulesGeneralActions.addMedicationSchedules,
  medicationTaskAmounts: MedicationTaskAmountsGeneralActions.addMedicationTaskAmounts,
  medicationTasks: MedicationTasksGeneralActions.addMedicationTasks,
  medications: MedicationsGeneralActions.addMedications,
  moodLogs: MoodLogsGeneralActions.addMoodLogs,
  notifications: NotificationsGeneralActions.addNotifications,
  omittedAmounts: OmittedAmountsGeneralActions.addOmittedAmounts,
  omittedMedications: OmittedMedicationsGeneralActions.addOmittedMedications,
  organizations: OrganizationsGeneralActions.addOrganizations,
  oxygenLogs: OxygenLogsGeneralActions.addOxygenLogs,
  pharmacies: PharmaciesGeneralActions.addPharmacies,
  pharmacyApiMessages: PharmacyApiMessagesGeneralActions.addPharmacyApiMessages,
  pharmacyPhones: PharmacyPhonesGeneralActions.addPharmacyPhones,
  positionLogs: PositionLogsGeneralActions.addPositionLogs,
  proposedChanges: ProposedChangesGeneralActions.addProposedChanges,
  prnAmounts: PrnAmountsGeneralActions.addPrnAmounts,
  prns: PrnsGeneralActions.addPrns,
  residentContactPhones: ResidentContactPhonesGeneralActions.addResidentContactPhones,
  residentContacts: ResidentContactsGeneralActions.addResidentContacts,
  residentDiets: ResidentDietsGeneralActions.addResidentDiets,
  residentGroupMembers: ResidentGroupMembersGeneralActions.addResidentGroupMembers,
  residentGroups: ResidentGroupsGeneralActions.addResidentGroups,
  residentInsurances: ResidentInsurancesGeneralActions.addResidentInsurances,
  residentLogTypes: ResidentLogTypesGeneralActions.addResidentLogTypes,
  residentMedicalProfessionals: ResidentMedicalProfessionalsGeneralActions.addResidentMedicalProfessionals,
  residentReports: ResidentReportsGeneralActions.addResidentReports,
  residents: ResidentsGeneralActions.addResidents,
  rolePermissions: RolePermissionsGeneralActions.addRolePermissions,
  roles: RolesGeneralActions.addRoles,
  rooms: RoomsGeneralActions.addRooms,
  seizureLogSymptoms: SeizureLogSymptomsGeneralActions.addSeizureLogSymptoms,
  seizureLogs: SeizureLogsGeneralActions.addSeizureLogs,
  shiftNotes: ShiftNotesGeneralActions.addShiftNotes,
  showerLogs: ShowerLogsGeneralActions.addShowerLogs,
  sleepLogs: SleepLogsGeneralActions.addSleepLogs,
  symptoms: SymptomsGeneralActions.addSymptoms,
  temperatureLogs: TemperatureLogsGeneralActions.addTemperatureLogs,
  urineLogs: UrineLogsGeneralActions.addUrineLogs,
  users: UserGeneralActions.addUsers,
  waterLogs: WaterLogsGeneralActions.addWaterLogs,
  weightLogs: WeightLogsGeneralActions.addWeightLogs,
};
