import { FacilityTimeService } from '$/app/services';
import { AlcDateInputComponent } from '$/app/shared/form-controls/date-input/date-input.component';
import { generateYears, getDateRange } from '$/app/utils';
import { DateRangePreset } from '$shared/services/filters/date-range-filter/date-range-presets';
import {
  Component,
  DestroyRef,
  OnDestroy,
  OnInit,
  computed,
  inject,
  input,
  model
} from '@angular/core';
import { takeUntilDestroyed, toSignal } from '@angular/core/rxjs-interop';
import {
  ControlValueAccessor,
  NgControl,
  UntypedFormBuilder,
  UntypedFormControl
} from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { find, isEqual } from 'lodash';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { AlcCommonModule } from '../../alc-common.module';
import { AlcThemePipe } from '../../pipes/theme.pipe';
import { TResettableControl } from '../../types/reactive-forms';

// DEPRECATED: Refactor this to use IDateRangeFilter from $shared.
// make sure to account for the mismatch types for startDate and endDate
export interface IDateRangePickerValue {
  type?: 'custom' | 'preset';
  year?: number;
  preset?: string;
  startDate?: DateTime;
  endDate?: DateTime;
}

@Component({
  selector: 'alc-date-range-picker-control',
  imports: [AlcCommonModule, AlcDateInputComponent, AlcThemePipe],
  templateUrl: './date-range-picker-control.component.html',
  styleUrls: ['./date-range-picker-control.component.scss']
})
export class AlcDateRangePickerControlComponent
  implements
    OnInit,
    OnDestroy,
    ControlValueAccessor,
    TResettableControl<IDateRangePickerValue>
{
  private readonly fb = inject(UntypedFormBuilder);
  private readonly ft = inject(FacilityTimeService);
  private readonly ngControl = inject(NgControl, {
    optional: true,
    self: true
  });
  private readonly destroyRef = inject(DestroyRef);

  private defaultSet = false;

  protected form = this.fb.group({
    type: ['preset'],
    year: [this.getCurrentYear()],
    preset: ['past30Days'],
    startDate: [null],
    endDate: [null]
  });

  protected presets: DateRangePreset[];
  protected years: number[] = [];

  value = model<IDateRangePickerValue>();
  defaultValue = input(
    {},
    {
      transform: (value: IDateRangePickerValue): IDateRangePickerValue => {
        const dateRange = getDateRange(value, this.ft);
        return dateRange;
      }
    }
  );
  excludePresets = input<string[]>();
  includePresets = input<string[]>();
  color = input<'primary' | 'accent'>('accent');

  formValue = toSignal(this.form.valueChanges);

  public isReset = computed(() => {
    return isEqual(this.formValue(), this.defaultValue());
  });

  private get yearCtrl() {
    return this.form.get('year') as UntypedFormControl;
  }

  private get presetCtrl() {
    return this.form.get('preset') as UntypedFormControl;
  }

  private get startDateCtrl() {
    return this.form.get('startDate') as UntypedFormControl;
  }

  private get endDateCtrl() {
    return this.form.get('endDate') as UntypedFormControl;
  }

  private destroyed$$ = new Subject<void>();

  constructor() {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (!this.ngControl) {
      this.presets = this.ft.generateDateRangePresets({
        year: this.value()?.year,
        includePresets: this.includePresets(),
        excludePresets: this.excludePresets()
      });

      const dateRange = getDateRange(this.value(), this.ft, 'past30Days');

      this.value.set(dateRange);

      this.form.setValue(dateRange);

      this.defaultSet = true;
    }

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.endDateCtrl.setErrors(null);

        if (this.endDateCtrl.value < this.startDateCtrl.value) {
          this.endDateCtrl.setErrors({ invalidDateRange: true });
          if (this.ngControl) {
            this.ngControl.control.setErrors({ invalidDateRange: true });
          }
        }

        this.value.set({
          ...value,
          endDate: value.endDate?.endOf('day')
        });

        this.onChange(this.value());
      });

    // NOTE: consider using the facility's creation year as the starting year
    // Could there ever be records before the facility's creetion date? This
    // sounds silly, but I'm thinking about records that are back-filled
    this.years = generateYears(2019);

    if (this.defaultSet) {
      this.onChange(this.value());
    }
  }

  ngOnDestroy() {
    this.destroyed$$.next();
    this.destroyed$$.complete();
  }

  /*  Control Value Accessor Implementation */
  onChange(_value: IDateRangePickerValue) {}
  onTouch: () => any = () => {};

  writeValue(value: IDateRangePickerValue) {
    this.presets = this.ft.generateDateRangePresets({
      year: value?.year,
      includePresets: this.includePresets(),
      excludePresets: this.excludePresets()
    });

    const dateRange = getDateRange(value, this.ft, 'past30Days');

    this.value.set(dateRange);

    if (!value) {
      this.defaultSet = true;
    }

    this.form.patchValue(dateRange, { emitEvent: false });
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }

  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  subscribeToChanges;

  public reset() {
    this.form.setValue(this.defaultValue());
  }

  protected onTypeChange(ev: MatRadioChange) {
    const type = ev.value;

    if (type === 'preset') {
      const preset = find(this.presets, { id: 'past30Days' });

      this.yearCtrl.setValue(this.getCurrentYear());
      this.presetCtrl.setValue(preset.id);
      this.startDateCtrl.setValue(preset.range.start);
      this.endDateCtrl.setValue(preset.range.end);
    }
  }

  protected onYearChange(ev: MatSelectChange) {
    const year = ev.value;
    const currentYear = this.getCurrentYear();

    this.presets = this.ft.generateDateRangePresets({
      year,
      includePresets: this.includePresets(),
      excludePresets: this.excludePresets()
    });
    let preset = find(this.presets, { id: this.presetCtrl.value });

    if (currentYear !== year && !preset) {
      preset = find(this.presets, { id: 'january' });
      this.presetCtrl.setValue(preset.id);
    }

    this.startDateCtrl.setValue(preset?.range?.start);
    this.endDateCtrl.setValue(preset?.range?.end);
  }

  protected onPresetChange(ev: MatSelectChange) {
    const preset = find(this.presets, { id: ev.value });

    this.form.get('startDate').setValue(preset?.range?.start);
    this.form.get('endDate').setValue(preset?.range?.end);
  }

  private getCurrentYear() {
    return DateTime.local({ zone: this.ft.timezone }).year;
  }
}
