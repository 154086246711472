import { capitalize, pick } from 'lodash';
import { PersonName } from '../../formatters/common';

export function parsePersonName<T extends PersonName>(
  name: string | T
): PersonName {
  const personName = {
    firstName: null,
    middleName: null,
    lastName: null,
    initials: null
  };

  if (typeof name === 'object') {
    return {
      ...personName,
      ...pick(name, ['firstName', 'middleName', 'lastName', 'initials']),
      initials: getInitials(name)
    };
  }

  let nameParts = name
    .split(',')
    .filter(Boolean)
    .map((part) => capitalize(part.trim()));

  // When a comma is present assume the last name is the first element and the first name is the second element.
  if (nameParts.length === 2) {
    const [lastName, otherNames] = nameParts;

    const [firstName, ...middleNames] = otherNames
      .split(' ')
      .filter(Boolean)
      .map((part) => capitalize(part.trim()));

    const middleName = middleNames?.length ? middleNames.join(' ') : null;

    return addInitials({ firstName, middleName, lastName });
  }

  nameParts = name
    .split(' ')
    .filter(Boolean)
    .map((part) => capitalize(part.trim()));

  // Assume the first name is the first element and the last name is the last
  // element and that everything in between is the middle name.
  if (nameParts.length > 1) {
    const [firstName, ...middleNameParts] = nameParts;
    const lastName = middleNameParts.pop() ?? null;

    const middleName = middleNameParts.length
      ? middleNameParts.join(' ')
      : null;

    return addInitials({ firstName, middleName, lastName });
  } else if (nameParts.length === 1) {
    return addInitials({
      firstName: nameParts[0],
      middleName: null,
      lastName: null
    });
  }

  return personName;
}

export function getInitials(personName: PersonName): string | null {
  if (personName.initials) {
    return personName.initials;
  }

  return (
    [personName.firstName, personName.lastName]
      .filter(Boolean)
      .map((name) => name?.charAt(0))
      .join('') || null
  );
}

function addInitials(personName: PersonName): PersonName {
  return {
    ...personName,
    initials: getInitials(personName)
  };
}
