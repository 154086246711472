import { trim } from 'lodash';
import { DateTime } from 'luxon';
import { Logger } from '../logger';
import { lookupValue } from '../lookups/lookup-value';
import { ToLuxonParam, valueOrDefault } from '../utils';
import { TFeetInches } from '../utils/converters/convert-inches-to-feet';
import { FacilityTime } from '../utils/date-time/facility-time';
import { getDateTimeFormat } from './date-time-formatter';

interface HasAddressFields {
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
}

export interface PersonName {
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  initials?: string | null;
}

export function phoneNumber(phoneNumber: string | null | undefined): string {
  if (!phoneNumber) {
    return '';
  }

  const cleaned = `${phoneNumber}`.replace(/\D/g, '');

  const parts = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (!parts) {
    return '';
  }

  const [, areaCode, prefix, suffix] = parts;

  return `(${areaCode}) ${prefix}-${suffix}`;
}

function middleName(
  person: PersonName | null | undefined,
  format: 'full' | 'initial' | 'none' = 'none'
): string | undefined {
  if (format === 'none' || !person?.middleName) {
    return undefined;
  }

  if (format === 'full') {
    return person.middleName;
  }

  if (format === 'initial') {
    return person.middleName.charAt(0) + '.';
  }

  Logger.throw('Unsupported "format" value', {
    person,
    format
  });
}

export function fullName(
  person: PersonName | null | undefined,
  middleFormat: 'full' | 'initial' | 'none' = 'none'
): string {
  return [person?.firstName, middleName(person, middleFormat), person?.lastName]
    .filter(Boolean)
    .join(' ');
}

export function fullAddress(
  item: HasAddressFields,
  fields: ('address1' | 'address2' | 'city' | 'state' | 'postalCode')[] = [
    'address1',
    'address2',
    'city',
    'state',
    'postalCode'
  ]
): string {
  const showAddress1 = fields.includes('address1');
  const showAddress2 = fields.includes('address2');
  const showCity = fields.includes('city');
  const showState = fields.includes('state');
  const showPostalCode = fields.includes('postalCode');

  return [
    showAddress1 && item.address1,
    showAddress2 && item.address2,
    showCity && item.city,
    showState && lookupValue('states', item.state, 'abbreviation'),
    showPostalCode && item.postalCode
  ]
    .filter(Boolean)
    .join(', ');
}

export function ordinal(number: number): string {
  const suffix = ['th', 'st', 'nd', 'rd'];
  const i = number % 100;
  return number + (suffix[(i - 20) % 10] || suffix[i] || suffix[0]);
}

export function gender<T extends { genderId?: string | null }>(
  gendered: T | null | undefined,
  initial: boolean = false
): string {
  const gender = lookupValue('genders', gendered?.genderId);

  if (!gender) {
    return '';
  }

  if (initial) {
    return gender.charAt(0);
  }

  return gender;
}

export function simpleDate(
  date: ToLuxonParam | null | undefined,
  ft: FacilityTime,
  fallbackValue?: any
): string {
  if (!date) {
    return fallbackValue ?? '';
  }

  return ft.createDate(date).toFormat('M-d-y');
}

export function simpleDateRange(
  start: ToLuxonParam,
  end: ToLuxonParam,
  ft: FacilityTime
): string {
  if (!start || !end) {
    return '';
  }

  return `${simpleDate(start, ft)} to ${simpleDate(end, ft)}`;
}

export function simpleTimestamp(
  date: ToLuxonParam | null | undefined,
  ft: FacilityTime
): string {
  if (!date) {
    return '';
  }

  return ft.convertDateTime(date).toFormat('M-d-y @ h:mm a');
}

export function simpleTime(timestamp: ToLuxonParam, ft: FacilityTime): string {
  if (!timestamp) {
    return '';
  }

  return ft.convertDateTime(timestamp).toFormat(getDateTimeFormat('TIME_12'));
}

export function time12Hour(time: string): string {
  if (!time) {
    return '';
  }

  return DateTime.fromFormat(time, getDateTimeFormat('TIME_FROM_24')).toFormat(
    getDateTimeFormat('TIME_12')
  );
}

export function yesOrNo(value: boolean | null | undefined): string {
  return value ? 'Yes' : 'No';
}

export function text(
  value: string | null | undefined,
  defaultValue: string = ''
): string {
  return valueOrDefault(value, defaultValue);
}

export function safeRender(
  value: string | null | undefined,
  fallback: string = '-' // default to dash
): string {
  value = trim(value ?? '');

  return value ? value : fallback;
}

export function feetInches({ feet, inches }: TFeetInches): string {
  return `${feet}' ${inches}"`;
}
