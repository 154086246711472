export const medicationTypes = [
  {
    id: 'antacid',
    name: 'Antacid'
  },
  {
    id: 'antiallergic',
    name: 'Antiallergic'
  },
  {
    id: 'antiarrhythmic',
    name: 'Antiarrhythmic'
  },
  {
    id: 'antihyperlipidemic',
    name: 'Antihyperlipidemic'
  },
  {
    id: 'antiinflammatory',
    name: 'Anti-inflammatory'
  },
  {
    id: 'antibiotic',
    name: 'Antibiotic'
  },
  {
    id: 'antidepressant',
    name: 'Antidepressant'
  },
  {
    id: 'antidiabetic',
    name: 'Antidiabetic'
  },
  {
    id: 'antidiarrhoeal', // british spelling
    name: 'Antidiarrheal'
  },
  {
    id: 'antiemetic',
    name: 'Antiemetic'
  },
  {
    id: 'antiepileptic',
    name: 'Antiepileptic'
  },
  {
    id: 'antifungal',
    name: 'Antifungal'
  },
  {
    id: 'antihistamine',
    name: 'Antihistamine'
  },
  {
    id: 'antihypertensive',
    name: 'Antihypertensive'
  },
  {
    id: 'antiparkinson',
    name: 'Antiparkinson'
  },
  {
    id: 'antipsychotic',
    name: 'Antipsychotic'
  },
  {
    id: 'anxiolytic',
    name: 'Anxiolytic (Anti-anxiety)'
  },
  {
    id: 'birthcontrol',
    name: 'Birth-control'
  },
  {
    id: 'bloodpressure',
    name: 'Blood-pressure'
  },
  {
    id: 'bloodthinner',
    name: 'Blood Thinner'
  },
  {
    id: 'diuretic',
    name: 'Diuretic'
  },
  {
    id: 'decongestant',
    name: 'Decongestant'
  },
  {
    id: 'hypnotic',
    name: 'Hypnotic'
  },
  {
    id: 'laxative',
    name: 'Laxative'
  },
  {
    id: 'memory',
    name: 'Memory'
  },
  {
    id: 'multivitamin',
    name: 'Multivitamin'
  },
  {
    id: 'muscle-relaxant',
    name: 'Muscle relaxant'
  },
  {
    id: 'other',
    name: 'Other'
  },
  {
    id: 'painkiller',
    name: 'Painkiller'
  },
  {
    id: 'probiotic',
    name: 'Probiotic'
  },
  {
    id: 'sedative',
    name: 'Sedative'
  },
  {
    id: 'stool-softener',
    name: 'Stool Softener'
  },
  {
    id: 'vitamin',
    name: 'Vitamin'
  }
] as const;

export const medicationTypeIds = medicationTypes.map(({ id }) => id);

export type MedicationTypeId = (typeof medicationTypeIds)[number];
