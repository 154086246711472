import { Facility, IFacilityGraph } from '$/models';
import { createActionGroup, emptyProps, props } from '@ngrx/store';

export const FacilitiesApiActions = createActionGroup({
  source: 'Facilities API',
  events: {
    'Load Facilities Success': props<{ facilities: Facility[] }>(),
    'Fetch Facility Success': props<{ facility: Facility }>(),
    'Fetch Facility Contacts Page Info Success': props<{
      facility: IFacilityGraph;
    }>(),
    'Create Facility Success': props<{ facility: Facility }>(),
    'Update Facility Success': props<{ facility: Facility }>(),
    'Delete Facility Success': props<{ id: string }>(),

    'Load Facilities Fail': props<{ error: Error }>(),
    'Fetch Facility Fail': props<{ error: Error }>(),
    'Create Facility Fail': props<{ error: Error }>(),
    'Update Facility Fail': props<{ error: Error }>(),
    'Delete Facility Fail': props<{ error: Error }>()
  }
});

export const FacilitiesWsActions = createActionGroup({
  source: 'Facilities WS',
  events: {
    'Facilities Created': props<{ facility: Facility }>(),
    'Facilities Patched': props<{ facility: Facility }>(),
    'Facilities Removed': props<{ id: string }>()
  }
});

export const FacilitiesGeneralActions = createActionGroup({
  source: 'Facilities General',
  events: {
    'Clear Facilities': emptyProps(),

    'Add Facilities': props<{ facilities: Facility[] }>()
  }
});
